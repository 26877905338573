/* eslint-disable no-lone-blocks */
import React from 'react';
import WelcomeBanner from '../../components/WelcomeBanner/WelcomeBanner';
import ScrollingBanner from '../../components/BandeauPromo/ScrollingBanner';
import './Home.css'
{/* import Works from '../Works/Works';
import Pricing from '../Pricing/Pricing';
import Contact from '../Contact/Contact';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/AllHeader/Header/Header';
import InfiniteMovingCards from '../../components/InfiniteMovingCards/InfiniteMovingCards';
import Features from '../../components/Features/Features';*/}


function Home() {
  return (
    <div className="Home">
      <ScrollingBanner />
      <WelcomeBanner/>
     {/* <Header /> 
      <Features />
      <InfiniteMovingCards/>
      <Works />
      <Pricing  />
      <Contact  />
      <Footer />*/}
    </div>
  );
}

export default Home;