import React from 'react';
import './ScrollingBanner.css';

const ScrollingBanner = () => {
  const message = "Site en reconstruction !";

  return (
    <div className="w-full overflow-hidden">
      <div className="scrollerBandeau whitespace-nowrap py-2">
        <div className="inline-block">
          {[...Array(24)].map((_, index) => (
            <span key={index} className="inline-flex items-center px-4">
              <i className="fa-solid fa-person-digging text-white text-xl mr-2"></i>
              <span className="text-white font-medium">{message}</span>
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ScrollingBanner;